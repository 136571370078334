import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Notedown is a browser-based markdown note-taking app, and it has MathJax support for equations. It is built with Vue.js and uses Firebase for authentication and storage. It was developed collaboratively as part of a class.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/notedown.png",
        "alt": "Notedown"
      }}></img></p>
    <h2><a parentName="h2" {...{
        "href": "https://notedown-web.web.app/"
      }}>{`Website`}</a></h2>
    <h2>{`Source`}</h2>
    <p>{`Source code for Notedown is available `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/notedown"
      }}>{`on Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      